import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search"
export default class extends Controller {
  static values = {
    endpoint: String,
    skipIds: Array
  }

  static targets = [
    "searchResults",
    "issueIdField",
    "submit"
  ]

  connect() {
    this.formAction = this.element.action;

    // if search results are visible, hide them on any click outside the search box
    document.addEventListener('click', (event) => {
      if (!event.target.closest('.search-results') && !event.target.closest('.search')) {
        this.hideSearchResults();
      }
    });

    // Handle arrow keys to navigate search results
    this.currentFocusIndex = -1;
    document.addEventListener('keydown', (event) => this.handleKeydown(event));
  }

  handleKeydown(event) {
    const resultDivs = Array.from(event.currentTarget.querySelectorAll('.search-results .list-group-item'));
    if (resultDivs.length === 0) return;
    
    const searchInput = event.currentTarget.querySelector('[name="search_input"]');

    switch (event.key) {
      case 'ArrowUp':
        this.currentFocusIndex = this.currentFocusIndex === -1 ? resultDivs.length - 1 : (this.currentFocusIndex - 1 + resultDivs.length) % resultDivs.length;
        break;
      case 'ArrowDown':
        this.currentFocusIndex = this.currentFocusIndex === -1 ? 0 : (this.currentFocusIndex + 1) % resultDivs.length;
        break;
      case 'Enter':
        if (this.currentFocusIndex !== -1) {
          resultDivs[this.currentFocusIndex].click();
        }
        return;
      default:
        this.currentFocusIndex = -1;
        searchInput.focus();
        return;
    }

    event.preventDefault();
    if (this.currentFocusIndex !== -1) {
      resultDivs[this.currentFocusIndex].focus();
    }
  }

  submit(event) {
    event.preventDefault();
    const searchInputValue = event.currentTarget.querySelector('[name="search_input"]').value;
    const query = searchInputValue.trim();

    if (query.startsWith('#') && !isNaN(query.substring(1))) {
      window.location.href = `${this.endpointValue}?scoped_id=${encodeURIComponent(query.substring(1))}`;
    } else if (!isNaN(query) && query.length > 0) {
      window.location.href = `${this.endpointValue}?scoped_id=${encodeURIComponent(query)}`;
    } else if (query.length >= 3) {
      window.location.href = `${this.endpointValue}?query=${encodeURIComponent(query)}`;
    }
  }

  areSearchResultsVisible() {
    document.querySelectorAll('.search-results').forEach((element) => {
      return element.style.display !== 'none';
    });

    return false
  }

  hideSearchResults() {
    document.querySelectorAll('.search-results').forEach((element) => {
      element.style.display = 'none';
    });
  }

  updateFrame(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const query = event.target.value.trim();
      const url = `${this.endpointValue}?skip_ids=${this.skipIdsValue.join(',')}&partial=true`

      if (query.startsWith('#') && !isNaN(query.substring(1))) {
        this.searchResultsTarget.src = `${url}&scoped_id=${encodeURIComponent(query.substring(1))}`;
      } else if (!isNaN(query) && query.length > 0) {
        this.searchResultsTarget.src = `${url}&scoped_id=${encodeURIComponent(query)}`;
      } else if (query.length >= 3) {
        this.searchResultsTarget.src = `${url}&query=${encodeURIComponent(query)}`;
      } else {
        this.hideSearchResults()
      }
    }, 200); // Delay to prevent too frequent requests
  }

  selectIssue(event) {
    const issueElement = event.target.closest('[data-issue-id]');

    if (issueElement) {
      // Read data-issue-id from the parent element
      const issueId = issueElement.dataset.issueId;
      this.issueIdFieldTarget.value = issueId;
    } else {
      console.error('Issue element not found');
    }

    if (this.formAction.includes('_issue_id_')) {
      this.element.action = this.formAction.replace('_issue_id_', this.issueIdFieldTarget.value);
    }

    // this controller is connected to the form
    this.element.submit();
  }
}

window.selectIssue = function (issueId) {
  document.getElementById('issue_id').value = issueId;
  document.getElementById('issue_search_form').submit();
}
