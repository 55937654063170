// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "./custom/videojs_reload"
import "./custom/timezone"
import "./custom/recent_issues"
import ClipboardJS from 'clipboard';

function initializeClipboard() {
    // Initialize tooltips
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl);
    });

    const clipboard = new ClipboardJS('.btn[data-clipboard-target]');

    // Optional: Add event listeners for clipboard.js events
    clipboard.on('success', function (e) {
        console.log('Text copied:', e.text);

        e.trigger.setAttribute('data-bs-original-title', 'Copied!');
        const tooltipInstance = bootstrap.Tooltip.getInstance(e.trigger);
        tooltipInstance.show();

        // Reset the tooltip text after a short delay
        setTimeout(() => {
            e.trigger.setAttribute('data-bs-original-title', 'Copy to clipboard');
            tooltipInstance.hide();
        }, 1000);

        e.clearSelection();
    });

    clipboard.on('error', function (e) {
        console.error('Action:', e.action);
        console.error('Trigger:', e.trigger);
    });
}

document.addEventListener('turbo:render', () => {
    initializeClipboard();
    // check if Dropzone is defined
    if (typeof Dropzone !== 'undefined') {
        Dropzone.autoDiscover = false;
    }
});

document.addEventListener('turbo:load', (event) => {
    // This updates Matomo with the current page URL
    if (typeof _paq !== 'undefined') {
        // console.log('Matomo is loaded');
        _paq.push(['setCustomUrl', event.detail.url]);
        _paq.push(['setDocumentTitle', document.title]);
        // _paq.push(['trackPageView']); // not needed, since it's triggeted by Google Tag Manager turbo:load event
    }

    // let the Google Tag Manager know that the page has been loaded
    if (typeof dataLayer !== 'undefined') {
        dataLayer.push({'event': 'turbo:load'});
    }

    initializeClipboard();
});

// on document ready
document.addEventListener('DOMContentLoaded', () => {
    // BetaHub Console Easter Egg
    console.log("%cWelcome to BetaHub's secret lair!", "font-size: 24px; font-weight: bold; color: #7289DA;");
    console.log("%cYou've found our hidden console message. Nice bug-hunting skills!", "font-size: 16px; color: #43B581;");
    console.log("%c", "font-size: 100px; background: url(https://media.giphy.com/media/LmNwrBhejkK9EFP504/giphy.gif) no-repeat; background-size: contain;");
    console.log("%cFeel free to report any bugs you find... or create new ones. We love a challenge!", "font-size: 16px; color: #FAA61A;");
    console.log("%cHappy gaming and bug squashing!", "font-size: 18px; font-weight: bold; color: #7289DA;");
});