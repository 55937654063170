import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form"
export default class extends Controller {
  submitForm(event) {
    event.preventDefault();
    const form = event.target.form;
    Turbo.navigator.submitForm(form);
  }
}
