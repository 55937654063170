// app/javascript/controllers/highlight_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["defaultTarget"]

  connect() {
    // Listen for custom events to trigger highlight
    this.element.addEventListener("trigger-highlight", () => {
      this.highlight()
    })

    // highlight if anchor is the same as this.element id
    if (window.location.hash === `#${this.element.id}`) {
      this.highlight()
    }
  }

  highlight() {
    this.element.classList.add("highlight")
    // Remove the highlight class after animation completes
    this.element.addEventListener("animationend", () => {
      this.element.classList.remove("highlight")
    }, { once: true })
  }
}
