import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="issue-strength-gauge"
export default class extends Controller {
  static targets = ["gauge"];

  connect() {
    this.initializeChart();

    // Binding the function and storing the reference
    this.boundHandleStrengthChange = this.handleStrengthChange.bind(this);

    // When the issue-strength-change event is dispatched, update the gauge
    document.body.addEventListener("issue-strength-change", this.boundHandleStrengthChange);

    // invoke event that the gauge is ready
    document.body.dispatchEvent(new CustomEvent("issue-strength-gauge-ready"));
  }

  disconnect() {
    // Removing the event listener
    document.body.removeEventListener("issue-strength-change", this.boundHandleStrengthChange);
  }

  initializeChart() {
    let el = this.gaugeTarget;

    // remove the inner div, recreate it, and append it to the element
    // this little trick is a workaround for turbo navigating back to a page
    el.innerHTML = '';
    const innerDiv = document.createElement('div');
    innerDiv.setAttribute('style', 'min-height: 300px;');
    el.appendChild(innerDiv);

    // Get options from data attribute
    var userOptions = utils.getData(innerDiv, 'options');
    this.chart = window.echarts.init(innerDiv);

    var getDefaultOptions = function getDefaultOptions() {
      return {
        series: [{
          radius: '100%',
          type: 'gauge',
          center: ['50%', '70%'],
          startAngle: 180,
          endAngle: 0,
          min: 0,
          max: 1,
          splitNumber: 8,
          axisLine: {
            lineStyle: {
              width: 6,
              color: [[0.25, utils.getColor('danger')], [0.5, utils.getColor('warning')], [0.75, utils.getColor('info')], [1, utils.getColor('success')]]
            }
          },
          pointer: {
            icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
            length: '12%',
            width: 20,
            offsetCenter: [0, '-60%'],
            itemStyle: {
              color: 'auto'
            }
          },
          axisTick: {
            length: 12,
            lineStyle: {
              color: 'auto',
              width: 2
            }
          },
          splitLine: {
            length: 20,
            lineStyle: {
              color: 'auto',
              width: 5
            }
          },
          axisLabel: {
            color: utils.getGrays()['600'],
            distance: -60,
            formatter: function formatter(value) {
              if (value === 0.875) {
                return 'Best';
              }

              if (value === 0.625) {
                return 'Good';
              }

              if (value === 0.375) {
                return 'Well';
              }

              if (value === 0.125) {
                return 'Bad';
              }

              return '';
            }
          },
          title: {
            offsetCenter: [0, '-20%'],
            color: utils.getGrays()['600']
          },
          detail: {
            offsetCenter: [0, '0%'],
            valueAnimation: true,
            formatter: function formatter(value) {
              return Math.round(value * 100);
            },
            color: 'auto'
          },
          data: [{
            value: 0.0,
            name: 'Grade'
          }]
        }]
      };
    };

    echartSetOption(this.chart, userOptions, getDefaultOptions);
  }

  handleStrengthChange(event) {
    this.chart.setOption({
        series: [{
        data: [{
            value: event.detail.value / 100
        }]
        }]
    });
  }
}
