import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="dynamic-dropdown"
export default class extends Controller {
  // targets
  static targets = ['bootstrapDropdownMenu']

  connect() {
    this.element.style.position = 'fixed';
  }

  setupEvents(target) {
    target.addEventListener('keydown', (event) => {
        // ignore all events if not visible
        if (!this.isVisible()) {
          return;
        }

        if (event.key === 'ArrowDown') {
          this.selectNextOrFirstElement();
          event.preventDefault();
        } else if (event.key === 'ArrowUp') {
          this.selectPreviousOrLastElement();
          event.preventDefault();
        }

        // on enter, get the selected (if any) element id and dispatch the event
        else if (event.key === 'Enter') {
          if (this.selectedElementIndex !== null) {
            const elementId = this.elementId(this.selectedElementIndex);
            this.dispatchItemSelected(elementId)
          }

          event.preventDefault();
          event.stopPropagation();
        }
      }, true // no bubbling, otherwise I won't be able to capture 'Enter' key
    )
  }

  dispatchItemSelected(eventOrId) {
    let id;
    if (eventOrId instanceof Event) {
      id = eventOrId.target.dataset.itemId;
      eventOrId.preventDefault();
    } else {
      id = eventOrId;
    }

    this.dispatch('itemSelected', {detail: {id}});
  }

  show(target) {
    if (!this.eventsSetup) {
      this.setupEvents(target);
      this.eventsSetup = true;
    }

    this.element.style.display = 'block'

    // show the dropdown
    this.bootstrapDropdownMenuTarget.classList.add('show')

    // select the first element
    this.selectElement(0)
  }

  hide() {
    this.element.style.display = 'none'

    // hide the dropdown
    this.bootstrapDropdownMenuTarget.classList.remove('show')
  }

  // this method clears all the elements and adds all the items
  // each item should include html and id
  setItems({items}) {
    this.bootstrapDropdownMenuTarget.innerHTML = ''

    items.forEach(({id, html}) => {
      const element = document.createElement('li')
      element.innerHTML = `<a class="dropdown-item" href="#" data-item-id="${id}" data-action="click->dynamic-dropdown#dispatchItemSelected">${html}</a>`
      this.bootstrapDropdownMenuTarget.appendChild(element);
    });
  }

  setPosition({x, y}) {
    this.element.style.left = `${x}px`
    this.element.style.top = `${y}px`
  }

  isVisible() {
    return this.bootstrapDropdownMenuTarget.classList.contains('show')
  }

  selectElement(index) {
    const elements = this.bootstrapDropdownMenuTarget.querySelectorAll('a');
    elements.forEach((element, i) => {
      if (i === index) {
        element.classList.add('highlighted');
        // element.focus();
      } else {
        element.classList.remove('highlighted');
      }
    });

    this.selectedElementIndex = index;
  }

  selectNextOrFirstElement() {
    const elementsCount = this.elementsCount();
    if (this.selectedElementIndex === null || this.selectedElementIndex === elementsCount - 1) {
      this.selectElement(0);
    } else {
      this.selectElement(this.selectedElementIndex + 1);
    }
  }

  selectPreviousOrLastElement() {
    const elementsCount = this.elementsCount();
    if (this.selectedElementIndex === null || this.selectedElementIndex === 0) {
      this.selectElement(elementsCount - 1);
    } else {
      this.selectElement(this.selectedElementIndex - 1);
    }
  }

  elementsCount() {
    return this.bootstrapDropdownMenuTarget.querySelectorAll('a').length;
  }

  elementId(index) {
    return this.bootstrapDropdownMenuTarget.querySelectorAll('a')[index].dataset.itemId;
  }
}
