import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        console.log("Projects controller connected")
    }

    delete(event) {
        let confirmed = confirm("Are you sure you want to delete this project?")

        if (!confirmed) {
            event.preventDefault()
        }
    }
}
