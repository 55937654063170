import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="description-check-type"
export default class extends Controller {
  static targets = [ "range", "display", "value" ]

  connect() {
    this.updateDisplay()
  }

  updateDisplay() {
    let value = this.rangeTarget.value
    let text, description, realValue

    switch(value) {
      case '0':
        realValue = 'no_check';
        text = 'No Checks';
        description = "All descriptions are accepted without any restrictions or checks. " +
            "Ideal for quick submissions where detail is not the primary concern.";
        break;
      case '1':
        realValue = 'character_limit';
        text = 'Minimum Characters Limit';
        description = "Descriptions must be at least 50 characters long. " +
            "This ensures a basic level of detail in submissions, " +
            "helping to provide enough context for effective bug analysis.";
        break;
      case '2':
        realValue = 'loose_ai_check';
        text = 'Loose AI Check';
        description = "Submissions are reviewed by AI for basic quality. Descriptions falling " +
            "short of standards are flagged for improvement. Reporters can then choose " +
            "to revise their description, enhancing the clarity and usefulness of bug reports.";
        break;
      case '3':
        realValue = 'strict_ai_check';
        text = 'Strict AI Check';
        description = "An advanced AI review is applied to each description. Submissions not meeting " +
            "the quality standards are rejected, with feedback provided for improvement. " +
            "This ensures high-quality, detailed reports that greatly aid in bug resolution. " +
            "<p><b>Note</b>: This stringent check might be frustrating for some users.</p>";
        break;
    }

    this.displayTarget.innerHTML = `<h5 class="fs-0">${text}</h5> <div>${description}</div>`
    this.valueTarget.value = realValue
  }
}