import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sentiments"
export default class extends Controller {

  static targets = [ "form", "dateField", "channelsField", "wordField", "categoryField", "wordColor"]
  connect() {
    // for all fields, submit the closes form on change
    this.dateFieldTarget.addEventListener("change", () => this.onDateChange())
    this.channelsFieldTarget.addEventListener("change", () => this.submitForm())
    this.wordFieldTarget.addEventListener("change", () => this.submitForm())

    this.updateButtonColors()

    // listen for turbo:frame-load
    document.addEventListener("turbo:frame-render", () => {
      this.updateButtonColors()
    })

    this.boundHandleCategoryClick = this.handleCategoryClick.bind(this)
    document.body.addEventListener("sentiment-category-click", this.boundHandleCategoryClick)
  }

  disconnect() {
    // disconnect events
    document.body.removeEventListener("sentiment-category-click", this.boundHandleCategoryClick)
  }

  onDateChange() {
    // submit only if from-to date is selected
    console.log(this.dateFieldTarget.value)
    if (this.dateFieldTarget.value.includes(" to ")) {
      this.submitForm()
    }
  }

  handleCategoryClick(event) {
    this.categoryFieldTarget.value = event.detail.id
    this.submitForm()
  }

  setButton(event) {
    this.wordFieldTarget.value = event.target.dataset.word
    this.submitForm()
  }

  clearWordFilter() {
    this.wordFieldTarget.value = ""
    this.submitForm()
  }

  clearCategoryFilter() {
    this.categoryFieldTarget.value = ""
    this.submitForm()
  }

  submitForm() {
    this.formTarget.requestSubmit()
  }

  updateButtonColors() {
    // for all button targets, find child .sentiment-color span, read data-score and set the color between
    // falcon success for 5 and falcon danger for 0
    this.wordColorTargets.forEach((span) => {
      const score = span.dataset.score
      const fiveColor = utils.hexToRgb(utils.getColor('success'));
      const zeroColor = utils.hexToRgb(utils.getColor('danger'));

      // mix the colors
      const color = this.mixColors(fiveColor, zeroColor, score / 5.0)

      // set text style
      span.style.color = color
    })
  }

  mixColors(color1, color2, weight) {
    var p = weight;
    var w = p * 2 - 1;
    var w1 = (w/1+1) / 2;
    var w2 = 1 - w1;
    var rgb = [Math.round(color1[0] * w1 + color2[0] * w2),
               Math.round(color1[1] * w1 + color2[1] * w2),
               Math.round(color1[2] * w1 + color2[2] * w2)];
    return 'rgb(' + rgb[0] + ',' + rgb[1] + ',' + rgb[2] + ')';
  }
}
