import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { voted: Boolean, projectId: Number, featureRequestId: Number}
  static targets = ["count"]

  connect() {
    this.updateVoteButton();
  }

  vote() {
    event.preventDefault(); // Prevent default anchor behavior

    const url = this.votedValue
        ? `/projects/${this.projectIdValue}/feature_requests/${this.featureRequestIdValue}/unvote`
        : `/projects/${this.projectIdValue}/feature_requests/${this.featureRequestIdValue}/vote`;

    const method = this.votedValue ? "DELETE" : "POST";

    fetch(url, {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content // Ensure you have CSRF token for Rails
      },
      body: JSON.stringify({}) // No need to send body for these requests
    }).then(response => response.json())
        .then(data => {
          this.votedValue = data.voted;
          // set or remove voted class from countTarget
          if (this.votedValue) {
            this.countTarget.classList.add("voted");
          } else {
            this.countTarget.classList.remove("voted");
          }

          this.countTarget.textContent = data.votes;
          this.updateVoteButton();
        }).catch(error => console.error("Error:", error));
  }

  updateVoteButton() {
    const button = this.element.querySelector(".vote-button");
    if (this.votedValue) {
      button.classList.add("voted");
    } else {
      button.classList.remove("voted");
    }
  }
}
