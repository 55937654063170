import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails";

// Connects to data-controller="issue-table-row"
// It's being set on <tr> element
export default class extends Controller {

  static values = {
    id: Number,
    issueUrl: String,
    issuesFrameUrl: String
  }

  updateStatus(event) {
    let status = event.currentTarget.getAttribute('data-status');

    const url = this.issueUrlValue;
    const token = document.querySelector('meta[name="csrf-token"]').content;
    fetch(url, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ issue: { status: status } })
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
      });

    this.removeFromTheTable();
    event.preventDefault();
  }

  updatePriority(event) {
    let priority = event.currentTarget.getAttribute('data-priority');

    console.log(this.issueFrameUrlValue)
    const url = this.issueUrlValue;
    const token = document.querySelector('meta[name="csrf-token"]').content;
    fetch(url, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ issue: { priority: priority } })
    })
      .then(response => response.json())
      .then(data => {
        Turbo.visit(this.issuesFrameUrlValue, { frame: 'issues_table' });
      });

    // this reloads the turboframe with src attribute set
    // document.getElementById('issues_table').reload();

    event.preventDefault();
  }

  deleteIssue(event) {
    // first ask using prompt dialog if user is sure
    let result = confirm("Are you sure you want to delete this issue?");

    if (result) {
      const url = this.issueUrlValue;
      const token = document.querySelector('meta[name="csrf-token"]').content;
      fetch(url, {
          method: 'DELETE',
          headers: {
          'X-CSRF-Token': token,
          'Content-Type': 'application/json'
          }
      })
          .then(response => response.json())
          .then(data => {
            console.log(data);
          });

      this.removeFromTheTable();
    }

    event.preventDefault();
  }

  removeFromTheTable() {
    this.element.classList.add('fadeAndCollapse');
    setTimeout(() => {
      this.element.classList.add('collapsing');
      // Wait for the animation to finish before removing the element.
      // This duration should match the longer of the two transitions defined in CSS.
      setTimeout(() => {
        this.element.remove();
      }, 500); // Adjust time based on your transition
    }, 10); // Short delay to ensure the class is added
  }
}
