import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="upload"
export default class extends Controller {
  static targets = ['dropzone', 'fileInput', 'previews', 'uploads'];
  static values = { modelName: String, attributeName: String };
  dropzone = null;

  connect() {
    const dropzoneElement = this.dropzoneTarget;
    const fileInputElement = this.fileInputTarget;
    const uploadsElement = this.uploadsTarget;
    let formData = new FormData();
    let dropzone = null;
    let modelName = this.modelNameValue;
    let attributeName = this.attributeNameValue;

    dropzone = new Dropzone(this.dropzoneTarget, {
      url: '#', // we do not want Dropzone to auto-upload files
      autoProcessQueue: false,
      addRemoveLinks: true,
      clickable: true,
      acceptedFiles: 'image/*', // or whatever file types you need
      maxFilesize: 8, // in MB
      previewsContainer: this.previewsTarget, // disable preview
      init: function() {
        this.on('dragenter', function() {
          dropzoneElement.classList.add('dragover');
        });

        this.on('dragleave', function() {
          dropzoneElement.classList.remove('dragover');
        });

        this.on('dragend', function() {
          dropzoneElement.classList.remove('dragover');
        });

        this.on('addedfile', (file) => {
          if (file.type.match(/image.*/)) {
            // Create a DataTransfer object
            let dataTransfer = new DataTransfer();

            // Add the file to the DataTransfer object
            dataTransfer.items.add(file);

            // Create a hidden input for the file
            const input = document.createElement('input');
            input.type = 'file';
            input.name = `${modelName}[${attributeName}][]`;
            input.files = dataTransfer.files;
            input.classList.add('d-none');

            // Append the input to the uploads target
            uploadsElement.appendChild(input);
          }
        });

        this.on('removedfile', (file) => {
          // Iterate over the inputs in the uploads container
          for (const input of uploadsElement.children) {
            // Check if the file attached to this input is the one that was removed
            if (input.files[0].name === file.name) {
              // Remove this input
              uploadsElement.removeChild(input);
              break;
            }
          }
        });
      },
    });

    this.dropzone = dropzone;
  }

  disconnect() {
    if (this.dropzone) {
      this.dropzone.destroy();
      this.dropzone = null;
    }
  }

  removeAttachment(event) {
    event.preventDefault();

    // Get the ID of the attachment to remove
    const id = event.currentTarget.dataset.attachmentId;

    // Create a hidden input to mark the attachment for removal
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = `${this.modelNameValue}[remove_attachments][]`;
    input.value = id;

    // Append the input to the form
    this.element.appendChild(input);

    // Remove the preview element
    event.currentTarget.parentNode.remove();
  }
}