import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="background-job"
export default class extends Controller {
  static targets = ["errorContainer"]
  static values = {
    message: String,
    pollInterval: { type: Number, default: 2000 },
    redirectUrl: String
  }

  connect() {
    this.form = this.element
    this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    this.form.addEventListener('submit', this.submit.bind(this))
  }

  submit(event) {
    event.preventDefault()

    // Clear previous errors
    if (this.hasErrorContainerTarget) {
      this.errorContainerTarget.innerHTML = ''
    }

    const url = this.form.action
    const method = this.form.method || 'post'
    const formData = new FormData(this.form)

    fetch(url, {
      method: method.toUpperCase(),
      body: formData,
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': this.csrfToken
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          return response.json().then(json => {
            throw json
          })
        }
      })
      .then(data => {
        const jobId = data.job_id
        this.showDialog()
        this.pollJobStatus(jobId)
      })
      .catch(error => {
        this.handleError(error)
      })
  }

  pollJobStatus(jobId) {
    const url = `/background_jobs/${jobId}`

    this.pollIntervalId = setInterval(() => {
      fetch(url, {
        headers: {
          'Accept': 'application/json',
          'X-CSRF-Token': this.csrfToken
        }
      })
        .then(response => response.json())
        .then(data => {
          const status = data.status
          const progress = data.progress

          // Optionally update the dialog with progress
          // this.updateProgress(progress)

          if (status === 'completed') {
            this.closeDialog()
            clearInterval(this.pollIntervalId)
            this.navigateToRedirectUrl()
          } else if (status === 'failed') {
            this.closeDialog()
            clearInterval(this.pollIntervalId)
            this.handleError({ error: data.message })
          }
        })
        .catch(error => {
          console.error('Error fetching job status:', error)
        })
    }, this.pollIntervalValue)
  }

  showDialog() {
    const message = this.messageValue || 'Please wait while we process your request...'

    // Create the modal HTML
    this.modal = document.createElement('div')
    this.modal.classList.add('modal', 'fade', 'show')
    this.modal.style.display = 'block'
    this.modal.innerHTML = `
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body text-center">
            <p>${message}</p>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    `
    document.body.appendChild(this.modal)
  }

  closeDialog() {
    if (this.modal) {
      document.body.removeChild(this.modal)
      this.modal = null
    }
  }

  handleError(error) {
    if (this.hasErrorContainerTarget) {
      this.errorContainerTarget.innerHTML = `<div class="alert alert-danger">${error.error || 'An error occurred'}</div>`
    } else {
      alert(error.error || 'An error occurred')
    }
  }

  navigateToRedirectUrl() {
    if (this.hasRedirectUrlValue) {
      window.location.href = this.redirectUrlValue
    } else {
      // Default behavior if no redirect URL is specified
      // For example, reload the current page
      window.location.reload()
    }
  }
}