import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["messages", "newMessage"]
  static values = { projectId: Number, userId: Number }

  connect() {
    this.refreshInterval = setInterval(() => {
      this.refreshMessages()
    }, 1500) // every 5 seconds
  }

  disconnect() {
    clearInterval(this.refreshInterval)
  }

  checkEnterKey(event) {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault()
      this.send(event)
    }
  }

  send(event) {
    event.preventDefault()

    let projectId = this.projectIdValue
    let userId = this.userIdValue

    fetch(`/projects/${this.projectIdValue}/chat_messages`, {
      method: 'POST',
      body: JSON.stringify({
        project_id: projectId,
        message: this.newMessageTarget.value,
        user_id: userId
      }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
    })
        .then(response => response.json())
        .then(data => {
          this.newMessageTarget.value = '';
          this.refreshMessages();
          // Scroll to bottom after sending a message.
          this.messagesTarget.scrollTop = this.messagesTarget.scrollHeight;
        })
  }

  refreshMessages() {
    const isScrolledToBottom = this.messagesTarget.scrollHeight - this.messagesTarget.clientHeight <= this.messagesTarget.scrollTop + 1;

    fetch(`/projects/${this.projectIdValue}/chat_messages.json?user_id=${this.userIdValue}`)
        .then(response => response.json())
        .then(data => {
          this.messagesTarget.innerHTML = data.map(message => `
        <div class="mb-2">
          <div class="text-muted small">
            ${new Date(message.created_at).toLocaleString()} <!-- This may need to be adjusted depending on your date format -->
          </div>
          <div>
            <strong class="text-primary">${message.sender.email}</strong>: <span class="ml-1">${message.message}</span>
          </div>
        </div>
      `).join('');

          // Scroll to bottom if it was already scrolled to the bottom before updating the messages.
          if (isScrolledToBottom) {
            this.messagesTarget.scrollTop = this.messagesTarget.scrollHeight;
          }
        })
  }


}
