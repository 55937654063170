import { Controller } from "@hotwired/stimulus"

const THEME_LOCAL_STORAGE_KEY = "playtesting-theme"

// Connects to data-controller="theme"
export default class extends Controller {
  static targets = [ "checkbox" ]

  connect() {
    const theme = this.getInfoFromLocalStorage()
    this.checkboxTarget.checked = theme === "dark";
    this.toggleTheme(theme)
  }

  getInfoFromLocalStorage() {
    const theme = localStorage.getItem(THEME_LOCAL_STORAGE_KEY)
    return (theme === undefined || theme === null) ? "dark" : theme
  }

  setInfoToLocalStorage(theme) {
    localStorage.setItem(THEME_LOCAL_STORAGE_KEY, theme)
  }

  toggleTheme(theme) {
    this.setInfoToLocalStorage(theme)
    if (theme === "dark") {
      document.documentElement.classList.add("dark")
    } else {
      document.documentElement.classList.remove("dark")
    }
  }

  toggle(event) {
    event.target.checked ? this.toggleTheme("dark") : this.toggleTheme("light")
  }
}
