import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="video"
export default class extends Controller {
  static values = {
    id: Number,
    projectId: Number,
    issueId: String,
    isProcessed: Boolean,
    createdAt: String,
    reloadInterval: Number
  };

  connect() {
    if (!this.isProcessedValue) {
      this.startReloading();
    }
  }

  disconnect() {
    this.stopReloading();
  }

  startReloading() {
    this.updateReloadInterval();
    this.timer = setInterval(() => {
      this.reload();
    }, this.reloadIntervalValue);
  }

  stopReloading() {
    clearInterval(this.timer);
  }

  async reload() {
    const response = await fetch(this.videoClipPartialURL(), {
      method: "GET",
      headers: {
        "Accept": "text/vnd.turbo-stream.html"
      }
    });

    if (response.ok) {
      const responseBody = await response.text();
      Turbo.renderStreamMessage(responseBody);

      // wait for a frame
      requestAnimationFrame(() => {
        const players = document.getElementsByClassName("video-js");
        for (let player of players) {
          // apply videojs scripts on player
          videojs(player);
        }
      });
    }
  }

  videoClipPartialURL() {
    return `/projects/${this.projectIdValue}/issues/${this.issueIdValue}/video_clips/${this.idValue}/reload`;
  }

  updateReloadInterval() {
    const elapsedTime = (new Date() - new Date(this.createdAtValue)) / 1000;

    // this.reloadIntervalValue = 5000

    if (elapsedTime <= 60) {
      this.reloadIntervalValue = 5000;
    } else if (elapsedTime <= 240) {
      this.reloadIntervalValue = 10000;
    } else if (elapsedTime <= 1800) {
      this.reloadIntervalValue = 30000;
    } else {
      this.stopReloading();
    }
  }
}
