import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "lastMinuteCaptureCheckbox" ]

    connect() {
        this.element.addEventListener('closeOverlay', this.closeOverlay.bind(this));
    }

    disconnect() {
        this.element.removeEventListener('closeOverlay', this.closeOverlay.bind(this));
    }

    discardIssue({ params }) {
        console.log("IPC: Discarding issue", params)

        const csrfToken = this.data.get("csrf-token")

        fetch(params.url, {
            method: "DELETE",
            headers: {
                "X-CSRF-Token": csrfToken,
            }
        }).then(this.closeOverlay)
    }

    closeOverlay() {
        console.log("IPC: Closing overlay")
        ipc.closeOverlay()
    }

    sendLastMinuteCaptureCheckboxValue() {
        console.log("IPC: Sending last minute capture checkbox value")
        const isChecked = this.lastMinuteCaptureCheckboxTarget.checked

        ipc.sendLastMinuteCaptureCheckboxValue(isChecked)
    }
}