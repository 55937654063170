import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template", "form"]

  connect() {
    this.sizeLimit = this.data.get('sizeLimit') || 8; // in MB
    this.maxFiles = this.data.get('maxFiles') || 5;

    // Convert sizeLimit to bytes for comparison
    this.sizeLimit = this.sizeLimit * 1024 * 1024;

    this.appendNewFileInput();
  }

  updateFileInputs() {
    // Find all file input elements in the form
    const fileInputs = this.formTarget.querySelectorAll('input[type="file"]')
    fileInputs.forEach((input, index) => {
      if (input.files.length > 0 || index === fileInputs.length - 1) {
        input.removeEventListener('change', this.handleFileInputChange.bind(this))
        input.addEventListener('change', this.handleFileInputChange.bind(this))
      }
    })
  }

  handleFileInputChange(event) {
    const fileInput = event.target;
    const file = event.target.files[0];

    // Check file size
    if (file.size > this.sizeLimit) {
      const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2); // size in MB
      alert(`The file size of ${fileSizeMB}MB exceeds the limit of ${this.sizeLimit / (1024 * 1024)}MB`);

      // Reset the file input
      fileInput.value = '';
      return;
    }

    const fileInputs = this.formTarget.querySelectorAll('input[type="file"]')
    const allFilled = Array.from(fileInputs).every(input => input.files.length > 0)

    // Only append a new file input if the maximum number of files is not reached
    if (allFilled && fileInputs.length < this.maxFiles) {
      this.appendNewFileInput();
    }
  }

  appendNewFileInput() {
    // Clone the template
    const clone = this.templateTarget.content.cloneNode(true)
    const input = clone.querySelector('input')

    // Find the current number of file inputs to set the name appropriately
    const fileInputs = this.formTarget.querySelectorAll('input[type="file"]')
    // const newIndex = fileInputs.length

    // Update the name attribute of the new input to reflect its index
    input.name = `feature_request[files][]`

    // Append the cloned template to the form
    this.formTarget.appendChild(clone)

    // Update event listeners for file inputs
    this.updateFileInputs()
  }
}
