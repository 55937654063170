import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="drawing-tool"
export default class extends Controller {
  static targets = ["canvas", "toolbar", "colorInput", "description"]

  static values = { patchPath: String, reloadPath: String, layerAUrl: String, backgroundImageUrl: String }

  connect() {
    this.ctx = this.canvasTarget.getContext('2d');
    this.setupCanvas();
    this.drawing = false;
    this.currentTool = 'pencil';
    this.currentColor = '#ff0000';
    this.lineWidth = 5;
    this.eraserWidth = 20;
    this.changesSaved = true;

    this.colorInputTarget.value = this.currentColor;

    // ask the user if they want to leave the page if they have unsaved changes
    this.boundBeforeUnload = this.beforeUnload.bind(this);
    document.addEventListener("turbo:before-visit", this.boundBeforeUnload)

    this.element.addEventListener('getAnnotationData', this.handleGetAnnotationData.bind(this));
  }

  disconnect() {
    this.element.removeEventListener('getAnnotationData', this.handleGetAnnotationData.bind(this));
    document.removeEventListener("turbo:before-visit", this.boundBeforeUnload)
  }

  beforeUnload(event) {
    if (!this.changesSaved) {
      if (!confirm("You have unsaved changes. Are you sure you want to leave?")) {
        event.preventDefault();
      }
    }
  }

  setupCanvas() {
    const image = new Image();
    image.onload = () => {
      this.canvasTarget.width = image.width;
      this.canvasTarget.height = image.height;

      requestAnimationFrame(() => {
        if (this.layerAUrlValue) {
          this.load(this.layerAUrlValue);
        }
      });

    };
    image.src = this.backgroundImageUrlValue;
  }

  startDrawing(event) {
    this.drawing = true;
    this.draw(event);
  }

  stopDrawing() {
    this.drawing = false;
    this.ctx.beginPath();
  }

  draw(event) {
    if (!this.drawing) return;
    this.ctx.lineWidth = this.currentTool === 'eraser' ? this.eraserWidth : this.lineWidth;
    this.ctx.lineCap = 'round';

    if (this.currentTool === 'eraser') {
      this.ctx.globalCompositeOperation = 'destination-out';
    } else {
      this.ctx.globalCompositeOperation = 'source-over';
      this.ctx.strokeStyle = this.currentColor;
    }

    let rect = this.canvasTarget.getBoundingClientRect();

    // Calculate scale factors for X and Y
    let scaleX = this.canvasTarget.width / rect.width;
    let scaleY = this.canvasTarget.height / rect.height;

    // Adjust mouse coordinates based on scale factor
    let x = (event.clientX - rect.left) * scaleX;
    let y = (event.clientY - rect.top) * scaleY;

    // return if x or y is outside of the canvas
    if (x < 0 || x > this.canvasTarget.width || y < 0 || y > this.canvasTarget.height) return;

    this.ctx.lineTo(x, y);
    this.ctx.stroke();
    this.ctx.beginPath();
    this.ctx.moveTo(x, y);

    this.changesSaved = false;
  }

  changeTool(event) {
    this.currentTool = event.target.value;

    // Remove 'active' class from all buttons
    this.toolbarTarget.querySelectorAll('button').forEach(button => {
      button.classList.remove('active');
    });

    // Add 'active' class to the selected button
    event.target.classList.add('active');
  }


  changeColor(event) {
    this.currentColor = event.target.value;
  }

  async save() {
    const imageBlob = await new Promise(resolve => this.canvasTarget.toBlob(resolve, 'image/png'));
    let formData = new FormData()
    formData.append("screenshot[layer_a]", imageBlob)

    // check if description target exists
    if (this.hasDescriptionTarget) {
      formData.append("screenshot[description]", this.descriptionTarget.value)
    }

    let xhr = new XMLHttpRequest()
    const url = this.patchPathValue.split('?')
    xhr.open("PATCH", `${url[0]}.json?${url[1]}`, true)
    xhr.onload = xhr.onerror = () => {
      if (xhr.status === 200) {
        console.log("success")
        this.changeSaved = true

        // reload the page
        window.location.href = this.reloadPathValue
      } else {
        console.log("error")
      }
    }
    xhr.send(formData)
  }

  async load(url) {
    const image = new Image();
    image.onload = () => this.ctx.drawImage(image, 0, 0);
    image.src = url;
  }

  async handleGetAnnotationData(event) {
    const imageBlob = await new Promise(resolve => this.canvasTarget.toBlob(resolve, 'image/png'));
    this.passDataToIssueForm(imageBlob);
  }

  passDataToIssueForm(canvasData) {
      const customEvent = new CustomEvent('screenshotAnnotationDataReceived', {
          bubbles: true,
          detail: { annotationData: canvasData }
      });
      this.element.dispatchEvent(customEvent);
  }
}