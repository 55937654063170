import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="device-form"
export default class extends Controller {
  async updateCpuModels(event) {
    const cpuBrand = event.target.value
    let url = `/devices/update_cpu_models?cpu_brand=${cpuBrand}`

    this.update(url)
  }

  async updateGpuModels(event) {
    const cpuBrand = event.target.value
    let url = `/devices/update_gpu_models?gpu_brand=${cpuBrand}`

    this.update(url)
  }

  async updateMobileModels(event) {
    const mobileBrand = event.target.value
    let url = `/devices/update_mobile_models?mobile_brand=${mobileBrand}`

    this.update(url)
  }

  async update(url) {
    const response = await fetch(url, {
      headers: { "Accept": "text/vnd.turbo-stream.html" },
      credentials: "same-origin"
    });

    if (response.ok) {
      const turboStream = await response.text();
      Turbo.renderStreamMessage(turboStream);

      requestAnimationFrame(() => {
        document.querySelectorAll('.js-choice').forEach((element) => {
          new Choices(element, { placeholder: true, searchResultLimit: 12 });
        });
      });
    } else {
      console.error(`Error fetching Turbo Stream: ${response.status}`);
    }
  }
}