// This code is responsible for setting a user's timezone when they first visit the site.

const setTimezone = () => {

    // this is set to false if user's timezone has not been set yet
    const timezoneSet = document.querySelector("#timezone_set");

    if (timezoneSet && timezoneSet.value === "false") {
        const currentUserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        let headers = {
            "Content-Type": "application/json",
        };

        const csrfToken = document.querySelector("meta[name=csrf-token]");
        if (csrfToken) {
            headers["X-CSRF-Token"] = csrfToken.content;
        }

        fetch(`/profiles/me/set_timezone`, {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify({ timezone: currentUserTimezone }),
        }).then((response) => {
            if (response.ok) {
                console.log("Timezone updated successfully");
            } else {
                console.error("Error updating timezone");
            }
        });
    }
}

document.addEventListener("turbo:render", setTimezone);
document.addEventListener("turbo:load", setTimezone);