import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "template", "promptLabel"];

  connect() {
    this.setLastStepListener();

    // Event delegation for dynamically added inputs
    this.element.addEventListener('keydown', event => {

      if (event.target.matches('[data-steps-to-reproduce-form-target="template"] input') && event.key === 'Enter' || event.key === 'Tab') {
        this.handleEnterKey(event);
      }
    });

    const lastStep = this.templateTargets.slice(-1)[0];
    const lastStepInput = lastStep.querySelector("input");
    if (lastStepInput.value !== "") {
      this.addStep();
    }

    document.body.addEventListener("steps-add-step", this.addStep.bind(this));

    const event = new CustomEvent("steps-ready");
    document.body.dispatchEvent(event);
  }

  setLastStepListener() {
    const lastStep = this.templateTargets.slice(-1)[0];
    if (lastStep) {
      lastStep.querySelector('.remove-button')?.remove(); // Remove the 'X' button from the last step
      lastStep.addEventListener("input", this.handleInput.bind(this));
    }
  }

  handleInput(event) {
    if (event.target.value !== "") {
      const lastStep = this.templateTargets.slice(-1)[0];
      const lastStepInput = lastStep.querySelector("input");

      if (event.target === lastStepInput) {
        this.addStep();
        this.setLastStepListener();
      }

      this.promptLabelTarget.classList.add("d-none");
    }
  }

  removeStep(event) {
    event.target.closest('[data-steps-to-reproduce-form-target="container"]').remove(); // remove the container
    this.updateStepsLabel();
  }

  addStep() {
    const stepNumber = this.templateTargets.length + 1;
    const newContainer = this.containerTarget.cloneNode(true);
    const newTemplate = newContainer.querySelector("[data-steps-to-reproduce-form-target='template']");
    const inputGroupText = newTemplate.querySelector(".input-group-text");
    const textField = newTemplate.querySelector(".form-control");

    // Add the 'X' button to all steps except the last one
    if (stepNumber > 1) {
      const removeButton = document.createElement('div');
      removeButton.classList.add('input-group-append', 'remove-button');
      removeButton.innerHTML = '<button class="btn btn-outline-secondary" type="button" tabindex="-1"><i class="fas fa-times" style="pointer-events: none;"></i></button>';
      removeButton.querySelector('button').addEventListener('click', this.removeStep.bind(this));
      this.templateTargets.slice(-1)[0].appendChild(removeButton);
    }

    inputGroupText.textContent = `${stepNumber}.`;
    textField.id = `step_${stepNumber}`;
    textField.value = "";

    this.element.appendChild(newContainer);
    this.setLastStepListener();
  }

  updateStepsLabel() {
    const templates = Array.from(this.templateTargets);
    templates.forEach((template, index) => {
      const inputGroupText = template.querySelector(".input-group-text");
      inputGroupText.textContent = `${index + 1}.`;
    });
  }

  setEnterKeyBehavior() {
    this.templateTargets.forEach(template => {
      const input = template.querySelector('input');
      if (input) {
        input.addEventListener('keydown', this.handleEnterKey.bind(this));
      }
    });
  }

  handleEnterKey(event) {
    if (event.key === 'Enter') {

      const nextInput = this.nextInput(event.target);
      if (nextInput) {
        nextInput.focus(); // Focus on the next step's input
        event.preventDefault();
      }
    }
  }

  nextStep(el) {
    let nextStep = el.closest('[data-steps-to-reproduce-form-target="container"]');

    do {
      nextStep = nextStep.nextElementSibling;
    } while (nextStep && !nextStep.matches('[data-steps-to-reproduce-form-target="container"]'));

    return nextStep;
  }

  nextInput(el) {
    let nextStep = this.nextStep(el);
    return nextStep?.querySelector('input');
  }

}
