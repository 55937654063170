// Function to add issue ID to recently visited list in local storage
window.addIssueToRecentList = function(issueId) {
  // Key for local storage
  const storageKey = 'recentlyVisitedIssues';

  // Retrieve the existing list from local storage
  let recentIssues = JSON.parse(localStorage.getItem(storageKey)) || [];

  // Add the current issue ID at the start of the array
  recentIssues.unshift(issueId);

  // Remove duplicates
  recentIssues = [...new Set(recentIssues)];

  // Limit the list to the last 8 issues
  if (recentIssues.length > 8) {
    recentIssues.length = 8;
  }

  // Save the updated list back to local storage
  localStorage.setItem(storageKey, JSON.stringify(recentIssues));
}

window.getRecentIssues = function() {
  // Key for local storage
  const storageKey = 'recentlyVisitedIssues';

  // Retrieve the list from local storage
  return JSON.parse(localStorage.getItem(storageKey)) || [];
}

// Function to display recently visited issues
function displayRecentIssues() {
  // Key for local storage
  const storageKey = 'recentlyVisitedIssues';

  // Retrieve the list from local storage
  let recentIssues = JSON.parse(localStorage.getItem(storageKey)) || [];

  // Display the list
  console.log('Recently Visited Issues:', recentIssues);
  // Implement your display logic here, such as updating the DOM or console output
}

// // Example usage:
// // Call this function when an issue page is visited
// addIssueToRecentList('Issue123');
//
// // Call this function wherever you need to show the recently visited issues
// displayRecentIssues();
