import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="issue-tags-form"
export default class extends Controller {
  static targets = ["list"];
  static values = { issueTags: String, projectIssueTags: String };

  connect() {
    this.issueTags = JSON.parse(this.issueTagsValue);
    const projectIssueTags = JSON.parse(this.projectIssueTagsValue);
    this.allTags = projectIssueTags.map((tag) => {
      tag.subtags = projectIssueTags.filter(
        (subtag) => subtag.parent_tag_id === tag.id
      );
      return tag;
    });

    this._redrawTagBadges();
  }

  _redrawTagBadges() {
    this.listTarget.innerHTML = "";
    this.issueTags.forEach((tag) => {
      const tagData = this.allTags.find((t) => t.id === tag.id);
      if (!tagData || tagData.parent_tag_id !== null) return;
      const subtags = tagData.subtags.filter((subtag) =>
        this.issueTags.some((issueTag) => issueTag.id === subtag.id)
      );
      this.listTarget.insertAdjacentHTML(
        "beforeend",
        this._createTagBadge(tagData, subtags)
      );
    });

    const remainingTags = this.allTags.filter(
      (tag) =>
        !this.issueTags.some((issueTag) => issueTag.id === tag.id) &&
        !tag.parent_tag_id
    );
    this.listTarget.insertAdjacentHTML(
      "beforeend",
      remainingTags.length > 0
        ? `
            <li>
              <select class="form-select-sm form-select" data-action="change->issue-tags-form#addTag">
                <option value="" selected disabled>Add tag</option>
                ${remainingTags.map(
                  (
                    tag
                  ) => `<option value="${tag.id}" data-color="${tag.color}" style="background-color: ${tag.color}; color: #333;">${tag.name}</option>
                    `
                )}
              </select>
            </li>
          `
        : ""
    );
  }

  _createTagBadge(tag, childrenTags = []) {
    const remainingSubtags = tag.subtags.filter(
      (subtag) => !childrenTags.some((childTag) => childTag.id === subtag.id)
    );

    return `
      <li class="d-flex justify-content-between align-items-center gap-2">
        <span class="issue-tag ${
          childrenTags.length > 0 ? "issue-tag-parent" : ""
        } prevent-hiding" style="--tag-color: ${tag.color};" data-id="${tag.id}">
          ${tag.name}
          <i class="fas fa-times cursor-pointer" data-action="click->issue-tags-form#removeTag"></i>
          ${childrenTags?.map(
              (childTag) => `
              <span class="issue-tag" style="--tag-color: ${childTag.color}" data-id="${childTag.id}">
                ${childTag.name}
                <i class="fas fa-times cursor-pointer" data-action="click->issue-tags-form#removeTag"></i>
                <input type="hidden" name="issue[tag_ids][]" value="${childTag.id}">
              </span>
            `
          ).join('')}
          <input type="hidden" name="issue[tag_ids][]" value="${tag.id}">
        </span>

        ${
          remainingSubtags.length > 0
            ? `
            <select class="form-select-sm form-select" style="width: 150px; max-height: 30px;" data-action="change->issue-tags-form#addTag">
              <option value="" selected disabled>Add subtag</option>
              ${remainingSubtags.map(
                (
                  subtag
                ) => `<option value="${subtag.id}" data-color="${subtag.color}" style="background-color: ${subtag.color}; color: #333;">${subtag.name}</option>
                  `
              )}
            </select>
            `
            : ""
        }
        
      </li>
    `;
  }

  addTag(e) {
    const tagId = Number(e.target.value);
    const tagData = this.allTags.find((tag) => tag.id === tagId);
    if (!tagData) return;

    this.issueTags.push({ ...tagData });
    this._redrawTagBadges();

    e.preventDefault();
  }

  removeTag(e) {
    const tagId = Number(e.target.closest(".issue-tag").dataset.id);
    const tagData = this.allTags.find((tag) => tag.id === tagId);
    if (!tagData) return;

    this.issueTags = this.issueTags.filter((tag) => {
      const isSubtag = this.issueTags.some(
        (parentTag) =>
          parentTag.id === tag.parent_tag_id && parentTag.id === tagId
      );
      return tag.id !== tagId && !isSubtag;
    });
    this._redrawTagBadges();

    e.preventDefault();
  }
}
