import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="integration-mapping-form"
export default class extends Controller {
  static targets = [
    "mappingType",
    "enumSelect",
    "mappingEntry", // a container for the mapping entry inside a form
  ]

  static values = {
    targetEndpoint: String, // endpoint where to submit the mappings
    targetMethod: String, // method to use when submitting the mappings
  }

  connect() {
    this.mappingTypeTargets.forEach((mappingType) => {
      mappingType.addEventListener("change", this.mappingTypeChange.bind(this))

      const event = new Event('change', {
        bubbles: true,
        cancelable: true,
      });

      mappingType.dispatchEvent(event);
    });

    this.enumSelectTargets.forEach((enumSelect) => {
      enumSelect.addEventListener("change", this.enumSelectChange.bind(this))

      const event = new Event('change', {
        bubbles: true,
        cancelable: true,
      });

      enumSelect.dispatchEvent(event);
    });
  }

  mappingTypeChange(event) {
    let fieldId = event.target.dataset.fieldId

    let fields = document.querySelectorAll(`.mapping-collection[data-field-id="${fieldId}"] .mapping-value`)
    fields.forEach((field) => {
      let mappingType = field.dataset.mappingType

      if (mappingType === event.target.value) {
        field.style.display = "block"
        this.toggleInputs(field, false)
      } else {
        field.style.display = "none"
        this.toggleInputs(field, true)
      }
    });
  }

  enumSelectChange(event) {
    let fieldId = event.target.dataset.fieldId
    let selectedEnumId = event.target.value

    let enumValuesContainers = document.querySelectorAll(`.enum-values[data-field-id="${fieldId}"]`)
    enumValuesContainers.forEach((container) => {
      if (container.dataset.selectFieldId === selectedEnumId) {
        container.style.display = "block"
        this.toggleInputs(container, false)
      } else {
        container.style.display = "none"
        this.toggleInputs(container, true)
      }
    });
  }

  toggleInputs(element, shouldDisable) {
    let inputs = element.querySelectorAll('input, select, textarea, button')
    inputs.forEach((input) => {
      input.disabled = shouldDisable
    })
  }

  update(event) {
    event.preventDefault();
    let mappings = this.buildMappingsJson();
    let mappings_json = { issue: mappings }

    fetch(this.targetEndpointValue, {
      method: this.targetMethodValue || 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Accept': 'application/json'
      },
      body: JSON.stringify(mappings_json)
    })
    .then(response => {
      if (response.ok) {
        // Handle success
        console.log("Mappings submitted successfully.");

        // Reload page with success message, pass as query param
        window.location.href = window
          .location
          .toString()
          .replace
          (window.location.search, "?success=true");
      } else {
        // Handle server errors
        console.error("Failed to submit mappings:", response.statusText);
      }
    })
    .catch(error => {
      // Handle network errors
      console.error("Error during submission:", error);
    });
  }

  // Builds mappings json in mappings json format from the form.
  // The way we do that this way instead of submitting the form is because the complexity of the form.
  // It's so big, it would be hard to manage the form data in the controller.
  // Therefore, it's easier to set a valid structure in the form and then build the json from the form.
  buildMappingsJson() {
    let json = {"mappings": []}

    let mappings = this.fetchAllMappings()
    mappings.forEach((mapping) => {
      console.log(mapping);
      json["mappings"].push(mapping)
    });

    return json;
  }

  fetchAllMappings() {
    let mappings = []
    let mappingContainers = this.mappingEntryTargets
    mappingContainers.forEach((container) => {
      let remoteField = container.querySelector('.remote-field')
      if (!remoteField) {
        // log error
        console.log("Remote field not found for container: ", container);
        return;
      }

      let mappingType = container.querySelector('.mapping-type')
      if (!mappingType) {
        // log error
        console.log("Mapping type not found for container: ", container);
        return;
      }

      let mapping = {
        remote: {
          id: remoteField.dataset.fieldId,
          name: remoteField.dataset.fieldName,
          local_type: remoteField.dataset.localFieldType,
          native_type: remoteField.dataset.nativeFieldType,
        },
      }

      switch (mappingType.value) {
        case "field":
          let remoteFieldIdSelect = container.querySelector('.local-field-id-select')
          if (!remoteFieldIdSelect) {
            // log error
            console.log("Local field id select not found for container: ", container);
            return;
          }

          mapping.local = {
            id: remoteFieldIdSelect.options[remoteFieldIdSelect.selectedIndex].dataset.fieldId,
            name: remoteFieldIdSelect.options[remoteFieldIdSelect.selectedIndex].dataset.fieldName,
            type: remoteFieldIdSelect.options[remoteFieldIdSelect.selectedIndex].dataset.fieldType
          }
          break;
        case "not_mapped": // do nothing
          break;
        case "constant":
          let constantValue = container.querySelector('.constant-value')
          if (!constantValue) {
            // log error
            console.log("Constant value not found for container: ", container);
            throw new Error("Constant value not found")
          }

          mapping.constant_value = constantValue.value
          break;
        case "dynamic":
          let dynamicValue = container.querySelector('.dynamic-value')
          if (!dynamicValue) {
            // log error
            console.log("Dynamic value not found for container: ", container);
            throw new Error("Dynamic value not found")
          }

          mapping.dynamic_expression = dynamicValue.value
          break;
        default:
          console.error("Invalid mapping type: ", mappingType.value)
          throw new Error("Invalid mapping type")
      }

      switch (mapping.remote.local_type) {
        case "enum":
          let values = []

          let enumValues = container.querySelectorAll('.enum-value')
          enumValues.forEach((enumValue) => {
            let value = {}
            value.local = {id: enumValue.dataset.valueId, name: enumValue.dataset.valueName}

            let remoteSelect = enumValue.querySelector('.remote-value-id-select')
            if (!remoteSelect) {
              // log error
              console.log("Remote value id select not found for container: ", enumValue);
              return;
            }

            value.remote = {
              id: remoteSelect.options[remoteSelect.selectedIndex].dataset.valueId,
              name: remoteSelect.options[remoteSelect.selectedIndex].dataset.valueName,
            }

            values.push(value)
          })

          mapping.values = values
          break;
        case "date": // do nothing
          break;
        case "string": // do nothing
          break;
        default:
          console.error("Invalid remote field type: ", mapping.remote.local_type, "for field: ", remoteField)
          throw new Error("Invalid remote field type")
      }

      mappings.push(mapping)

    })

    return mappings;
  }
}