import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="delete-confirm"
export default class extends Controller {
  static targets = ["input", "submit"]

  connect() {
    this.validate() // Ensure the button state is correct on load
  }

  validate() {
    const inputValue = this.inputTarget.value.trim().toLowerCase()
    if (inputValue === 'delete') {
      this.submitTarget.disabled = false
    } else {
      this.submitTarget.disabled = true
    }
  }
}
