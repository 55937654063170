import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="link-choice"
export default class extends Controller {
  static targets = ["previousRadio", "previousLink", "currentRadio", "currentLink", "enabledCheckbox"];

  connect() {
    this.toggleLink();
  }

  toggleLink() {
    if (this.hasPreviousLinkTarget) {
      this.previousLinkTarget.disabled = !this.previousRadioTarget.checked;

      this.enabledCheckboxTarget.disabled = this.previousRadioTarget.checked;
      this.currentLinkTarget.disabled = this.previousRadioTarget.checked || !this.enabledCheckboxTarget.checked;
    } else {
      this.currentLinkTarget.disabled = !this.enabledCheckboxTarget.checked;
    }
  }
}
