import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="articles-notifications"
export default class extends Controller {
    static targets = [ "toggleLink" ]
  connect() {
  }

  markAllAsRead() {
      if (this.toggleLinkTarget.classList.contains("notification-indicator")) {
          this.toggleLinkTarget.classList.remove("notification-indicator")

          fetch("/profiles/me/mark_all_news_read", {
              method: "PATCH",
              headers: {
                  "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
              }
          })
      }
  }
}
