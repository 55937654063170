import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tooltip2"
export default class extends Controller {
  static targets = ["tooltip"]

  connect() {
    this.timeout = null
    this.tooltipTarget.addEventListener('mouseover', () => { this.positionTooltip(); this.startShowTimeout() })
    this.tooltipTarget.addEventListener('mouseout', () => this.startHideTimeout())

    // Initialize MutationObserver
    this.observer = new MutationObserver(() => this.positionTooltip());
    this.observer.observe(this.tooltipTarget, { attributes: true, childList: true, subtree: true });

    // Listen for the 'hideTooltips' event
    document.addEventListener('hideTooltips', () => this.hideTooltip());
  }

  disconnect() {
    this.observer.disconnect();
    document.removeEventListener('hideTooltips', () => this.hideTooltip());
  }

  mouseOver(event) {
    if (!this.isVisible()) {
      this.positionTooltip(event)
      this.startShowTimeout()
    }
  }

  mouseOut(event) {
    this.startHideTimeout()
  }

  clearTimeout() {
    clearTimeout(this.timeout)
  }

  startHideTimeout() {
    this.clearTimeout()

    this.timeout = setTimeout(() => {
      this.hideTooltip()
    }, 500) // Hide tooltip delayed
  }

  hideTooltip() {
    this.tooltipTarget.style.display = 'none'
  }

  startShowTimeout() {
    this.clearTimeout()

    this.timeout = setTimeout(() => {
      const hideEvent = new CustomEvent('hideTooltips');
      document.dispatchEvent(hideEvent);

      this.tooltipTarget.style.display = 'block'
      this.positionTooltip()
    }, 500) // Show tooltip after 0.5s
  }

  isVisible() {
    return this.tooltipTarget.style.display !== 'none'
  }

  positionTooltip(event) {
    let tooltip = this.tooltipTarget;
    let x, y;

    if (event) {
      x = event.clientX;
      y = event.clientY;
      this.lastX = x;
      this.lastY = y;
    } else if (this.lastX && this.lastY) {
      x = this.lastX;
      y = this.lastY;
    } else {
      return; // If there's no event and no last position, we can't position the tooltip
    }

    let padding = 10; // Padding from cursor to tooltip

    // Repositioning to avoid clipping
    let screenW = window.innerWidth;
    let screenH = window.innerHeight;
    let tooltipWidth = tooltip.offsetWidth;
    let tooltipHeight = tooltip.offsetHeight;

    // Horizontal position
    if (x + tooltipWidth/2 + padding > screenW) {
      tooltip.style.left = (x - tooltipWidth - padding) + 'px';
    } else if (x - tooltipWidth/2 - padding < 0) {
      tooltip.style.left = padding + 'px';
    } else {
      tooltip.style.left = (x - tooltipWidth/2) + 'px';
    }

    // Vertical position
    if (y + tooltipHeight + padding > screenH) {
      tooltip.style.top = (y - tooltipHeight - padding) + 'px';
    } else {
      tooltip.style.top = (y + padding) + 'px';
    }
  }
}