import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="screenshot"
export default class extends Controller {
  static targets = [ "layerA" ]

  connect() {
    // TODO: how to make it better?
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }


  toggleLayerA(event) {
    this.layerATarget.classList.toggle("hidden")
    event.preventDefault()
  }
}
