import { Controller } from "@hotwired/stimulus"
import IssueFormController from "./issue_form_controller"

// Connects to data-controller="overlay-issue-form"
export default class extends IssueFormController {
  static targets = [
    "description",
    "stepsToReproduce",
    "stepsToReproduceLabel",
    "modalMessage",
    "warningModal",
    "errorModal",
    "attachScreenshotCheckbox",
    "errorMessageContainer",
    "errorMessage",
    "errorMessageCloseButton",
    "loadingScreenOverlay",
    "loadingScreenOverlayMessage",
  ];

  weights = {
    description: 0.6,
    stepsToReproduce: 0.4,
  };

  connect() {
    // log this dom element
    let form = this.element;
    form.addEventListener("submit", this.submitClicked.bind(this));

    this.element.addEventListener('screenshotAnnotationDataReceived', this.handleScreenshotAnnotationData.bind(this));
    this.errorMessageCloseButtonTarget.addEventListener('click', (event) => {
      event.preventDefault();
      this.errorMessageContainerTarget.classList.add('d-none');
    });
    document.addEventListener('submitAnyway', this.submitAnyway.bind(this));
  }

  disconnect() {
    this.element.removeEventListener('screenshotAnnotationDataReceived', this.handleScreenshotAnnotationData.bind(this));
    document.removeEventListener('submitAnyway', this.submitAnyway.bind(this));
  }

  getStepsToReproduce() {
    return this.stepsToReproduceTargets
        .map((step) => step.value)
        .filter((step) => step !== "");
  }

  getDescriptionLength() {
    return this.descriptionTarget.value.length;
  }

  checkDescription() {
    const descriptionLength = this.getDescriptionLength();

    if (descriptionLength == 0) {
      return { error: "Uh-oh! <b>It seems like you've left the description field empty</b>. Mind jotting down a few words to tell us about the issue?" };
    } else if (descriptionLength < 40) {
      return { error: "Whoops! <b>Your description is a bit on the brief side</b>. Could you expand it a bit more? More details can help us understand the issue better." };
    } else if (descriptionLength < 100) {
      return { warning: "Hey there! While your description is clear, <b>adding a few more details could make it even better</b>. However, if you think you've covered everything important, feel free to ignore this message." };
    }
    return null;
  }

  checkSteps() {
    const steps = this.getStepsToReproduce();

    if (steps.length == 0) {
      return { error: "Uh-oh! <b>It looks like you've forgotten to provide steps to reproduce the issue.</b> Would you mind adding them in? This will help us diagnose the problem much quicker." };
    } else if (steps.length < 3) {
      return { warning: "Hey! <b>We noticed that your steps to reproduce the issue are quite concise.</b> If there are any additional details that you think could be useful, do add them in. But if you believe everything's covered, feel free to proceed." };
    }
    return null;
  }

  submitClicked(event) {
    event.preventDefault();

    console.log("Submit clicked")

    const descriptionFeedback = this.checkDescription();
    const stepsFeedback = this.checkSteps();

    if (descriptionFeedback?.error || stepsFeedback?.error) {
      if (descriptionFeedback?.error) {
        return this.showDescriptionErrorPopover(descriptionFeedback.error);
      }
      if (stepsFeedback?.error) {
        return this.showStepsErrorPopover(stepsFeedback.error);
      }
      return;
    }

    if (descriptionFeedback?.warning || stepsFeedback?.warning) {
      if (descriptionFeedback?.warning) {
        return this.showDescriptionWarningPopover(descriptionFeedback.warning);
      }
      if (stepsFeedback?.warning) {
        return this.showStepsWarningPopover(stepsFeedback.warning);
      }
      return;
    }

    this.submitForm();
  }

  createPopover(target, content) {
    // target.setAttribute("data-bs-content", content);

    const defaultAllowList = bootstrap.Popover.Default.allowList; // Get Bootstrap's default allow list
    // Add `button` element and `class` attribute to the allow list
    const myAllowList = {
      ...defaultAllowList,
      button: ['class'],
    };

    const popover = new bootstrap.Popover(target, {
      trigger: 'manual',
      placement: 'left',
      html: true,
      content: content,
      sanitize: false,
    });

    const hidePopover = function() {
      popover.hide();
    }

    setTimeout(() => {
      document.addEventListener('click', function(event) {
        const popoverNode = document.querySelector('.popover'); // the currently shown popover
        if (popoverNode && !popoverNode.contains(event.target) && !target.contains(event.target)) {
          hidePopover();
        }
      });

      document.addEventListener('keydown', hidePopover);
    }, 0);

    popover.show();

    // Return a function to remove event listeners when they are not needed anymore
    return function removeEventListeners() {
      document.removeEventListener('click', hidePopover);
      document.removeEventListener('keydown', hidePopover);
    }
  }

  showErrorPopover(target, message) {
    this.createPopover(target, message);
  }

  showWarningPopover(target, message) {
    let event = new CustomEvent("submitAnyway", {
      detail: {},
      bubbles: true,
      cancelable: true
    });

    this.createPopover(target, message + '<div class="text-end mt-3"><button id="submitAnywayButton" class="btn btn-warning btn-sm">Submit Anyway</button></div>');
    document.getElementById("submitAnywayButton").addEventListener("click", function() {
        // Dispatch the event when the button is clicked
        document.dispatchEvent(event);

        // close the popover
        bootstrap.Popover.getInstance(target).hide();
    });

  }

  // Then use these in your existing methods:

  showDescriptionErrorPopover(message) {
    this.showErrorPopover(this.descriptionTarget, message);
  }

  showStepsErrorPopover(message) {
    this.showErrorPopover(this.stepsToReproduceLabelTarget, message);
  }

  showDescriptionWarningPopover(message) {
    this.showWarningPopover(this.descriptionTarget, message);
  }

  showStepsWarningPopover(message) {
    this.showWarningPopover(this.stepsToReproduceLabelTarget, message);
  }

  submitAnyway() {
    this.submitForm();
  }

  submitForm() {
    this.showLoadingScreen();

    const screenshotEditorElement = this.element.querySelector('[data-controller="screenshot-editor"]');
    const customEvent = new CustomEvent('getAnnotationData', {
      bubbles: true, // so the event can bubble up
      detail: {}     // data you might want to send, if any
    });
    screenshotEditorElement.dispatchEvent(customEvent);
  }

  showLoadingScreen() {
    const bugSubmissionMessages = [
      "Hang tight! We're dispatching our best bug detectives.",
      "Hold on, the bug exterminators are on their way!",
      "Brewing some magic potions to fix those bugs.",
      "Summoning the code wizards to tackle this bug.",
      "Stay calm! Our digital elves are working on it."
    ];

    const randomMessage = bugSubmissionMessages[Math.floor(Math.random() * bugSubmissionMessages.length)];
    this.loadingScreenOverlayMessageTarget.innerHTML = randomMessage;

    this.loadingScreenOverlayTarget.classList.remove('d-none');

    // add a timeout to hide the loading screen after 30 seconds
    this.loadingScreenTimeout = setTimeout(() => {
      this.hideLoadingScreen();
      this.showErrorMessage("Uh-oh! Looks like something went wrong. Please try again later.");
    }, 30000);
  }

  hideLoadingScreen() {
    this.loadingScreenOverlayTarget.classList.add('d-none');

    // cancel timeout
    clearTimeout(this.loadingScreenTimeout);
  }

  handleScreenshotAnnotationData(event) {
    console.log("received screenshot annotation data");
    console.log(event)

    let formData = new FormData(this.element);
    if (this.attachScreenshotCheckboxTarget.checked) {
      formData.append("screenshot[layer_a]", event.detail.annotationData);
    }

    let csrfToken = document.querySelector("meta[name=csrf-token]");
    let headers = {
        "Accept": "application/json",
        "X-Requested-With": "XMLHttpRequest"
    };

    if (csrfToken) {
        headers["X-CSRF-Token"] = csrfToken;
    }

    fetch(this.element.action, {
        method: this.element.method,
        headers: headers,
        body: formData
    })
    .then(response => response.json())
    .then((data) => {
      console.log("got data")
      console.log(data)
      if (!data.error) {
        const closeOverlayEvent = new CustomEvent('closeOverlay', {
          bubbles: true,
          detail: {}
        });
        // this.element.dispatchEvent(closeOverlayEvent);
        console.log("IPC: Bug submitted")
        // call only if ipc is available
        if (typeof ipc !== 'undefined') {
          ipc.onBugReportSent()
        }

        // hide loading screen
        this.hideLoadingScreen();

        // display thankYouModal
        // bootstrap.Modal.getOrCreateInstance(document.getElementById('submit-modal')).hide();
        bootstrap.Modal.getOrCreateInstance(document.getElementById('thankYouModal')).show();

        // on close, close overlay
        document.getElementById('thankYouModal').addEventListener('hidden.bs.modal', function (event) {
          console.log("Thank you modal closed")
          console.log("IPC: Closing overlay")
          // call only if ipc is available
          if (typeof ipc !== 'undefined') {
            ipc.closeOverlay()
          }
        });
      } else {
        this.hideLoadingScreen();
          this.showErrorMessage(data.error);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      this.showErrorMessage(error);
    });
  }

  showErrorMessage(message) {
    this.errorMessageContainerTarget.classList.remove('d-none');
    this.errorMessageTarget.innerHTML = message;
  }
}
