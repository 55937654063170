import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sentiment-analysis-charts"
export default class extends Controller {

  static targets = [ "trendChart", "pieChart", "stackedChart" ]

  connect() {
    this.initializeCharts();

    document.addEventListener('turbo:load', () => {
      this.initializeCharts();
    });

    document.addEventListener('turbo:frame-load', () => {
      this.initializeCharts();
    });
  }

  initializeCharts() {
    this.trendChartTargets.forEach(el => {
      this.initTrendChart(el);
    });

    this.stackedChartTargets.forEach(el => {
      this.initStackedBarChart(el);
    });

    this.initPieChart();
  }

  initTrendChart(el) {
    el.innerHTML = '';
    const innerDiv = document.createElement('div');
    innerDiv.setAttribute('style', `min-height: ${el.dataset.height}px;`);
    el.appendChild(innerDiv);

    let chart = echarts.init(innerDiv);

    if (chart) {
      let dates = JSON.parse(el.dataset.dates);
      let values = JSON.parse(el.dataset.values);
      let valueLabel = el.dataset.valuelabel;
      let lineColor = el.dataset.linecolor;
      let max = el.dataset.max;

      let getDefaultOptions = function () {
        return {
          tooltip: {
            trigger: 'axis',
            padding: [7, 10],
            backgroundColor: utils.getGrays()['100'],
            borderColor: utils.getGrays()['300'],
            textStyle: {color: utils.getGrays()['1100']},
            borderWidth: 1,
            transitionDuration: 0,
            formatter: function (params) {
              return params[0].axisValueLabel + '<br/>' +
                valueLabel + ': ' + params[0].data.toFixed(1);
            }
          },
          xAxis: {
            type: 'category',
            data: dates,
            boundaryGap: false,
            axisLine: {
              lineStyle: {
                color: utils.rgbaColor('#000', 0.01),
                type: 'dashed'
              }
            },
            axisLabel: {
              color: utils.getColor('gray-400'),
              formatter: function (value) {
                return new Date(value).toLocaleDateString('en-US', {month: 'short', day: 'numeric'});
              },
              margin: 15
            }
          },
          yAxis: {
            type: 'value',
            min: 0,
            max: max,
            splitLine: {
              lineStyle: {
                color: utils.getColor('gray-300'),
                type: 'dashed'
              }
            },
            axisLabel: {
              show: true,
              color: utils.getColor('gray-400'),
              margin: 15,
              formatter: function (value) {
                return value.toFixed(0);
              }
            }
          },
          series: [{
            type: 'line',
            data: values,
            smooth: true,
            lineStyle: {color: utils.getColor(lineColor)},
            itemStyle: {
              borderColor: utils.getColor(lineColor),
              borderWidth: 2
            },
            symbol: 'circle',
            symbolSize: 10,
            hoverAnimation: true,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: utils.rgbaColor(utils.getColor('primary'), 0.2)
                }, {
                  offset: 1,
                  color: utils.rgbaColor(utils.getColor('primary'), 0)
                }]
              }
            }
          }],
          grid: {
            right: '3%',
            left: '6%',
            bottom: '10%',
            top: '5%'
          }
        };
      };

      echartSetOption(chart, {}, getDefaultOptions);

      // Resize chart on window resize
      window.addEventListener('resize', function () {
        chart.resize();
      });
    }
  }

  initStackedBarChart(el) {
    el.innerHTML = '';

    const innerDiv = document.createElement('div');
    innerDiv.setAttribute('style', 'min-height: 400px;');
    el.appendChild(innerDiv);

    const chart = echarts.init(innerDiv);

    if (chart) {
      const dates = JSON.parse(el.dataset.dates);
      const positive = JSON.parse(el.dataset.positive);
      const negative = JSON.parse(el.dataset.negative);
      const neutral = JSON.parse(el.dataset.neutral);
      const toxic = JSON.parse(el.dataset.toxic);
      const excited = JSON.parse(el.dataset.excited);
      const frustrated = JSON.parse(el.dataset.frustrated);
      const max = el.dataset.max ? parseFloat(el.dataset.max) : null;

      const sentiments = [positive, negative, neutral, toxic, excited, frustrated];
      const totalData = dates.map((_, i) => sentiments.reduce((sum, arr) => sum + arr[i], 0));

      const normalizedData = sentiments.map(arr => arr.map((val, i) => totalData[i] > 0 ? val / totalData[i] : 0));

      const series = [
        { name: 'Positive', data: normalizedData[0], color: utils.getColor('primary') },
        { name: 'Negative', data: normalizedData[1], color: utils.getColor('danger') },
        { name: 'Neutral', data: normalizedData[2], color: utils.getColor('secondary') },
        { name: 'Toxic', data: normalizedData[3], color: utils.getColor('warning') },
        { name: 'Excited', data: normalizedData[4], color: utils.getColor('success') },
        { name: 'Frustrated', data: normalizedData[5], color: utils.getColor('info') }
      ].map(({ name, data, color }) => ({
        name,
        type: 'bar',
        stack: 'total',
        label: { show: true, formatter: (params) =>
            (params.value * 100).toFixed(1) + '%' },
        data,
        emphasis: {
          focus: 'series',
        },
        itemStyle: { color }
      }));

      const categoryWidth = chart.getWidth() / dates.length;
      const barPadding = categoryWidth * 0.2;

      // Define the chart options
      const getDefaultOptions = () => ({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow', // Use shadow to highlight the bar
          },
          padding: [7, 10],
          backgroundColor: utils.getGrays()['100'],
          borderColor: utils.getGrays()['300'],
          textStyle: { color: utils.getGrays()['1100'] },
          borderWidth: 1,
          transitionDuration: 0,
          formatter: (params) => {
            let tooltip = `${params[0].name}<br/>`;
            params.forEach(param => {
              tooltip += `${param.seriesName}: ${(param.value * 100).toFixed(1)}%<br/>`;
            });
            return tooltip;
          },
        },
        legend: {
          data: ['Positive', 'Negative', 'Neutral', 'Toxic', 'Excited', 'Frustrated'],
          textStyle: {
            color: utils.getGrays()['900'],
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          top: '10%',
          containLabel: true,
        },
        yAxis: {
          type: 'value',
          max: max, // Set maximum if provided
          splitLine: {
            lineStyle: {
              color: utils.getColor('gray-300'),
              type: 'dashed'
            }
          },
          axisLabel: {
            color: utils.getColor('gray-400'),
            margin: 15,
            formatter: (value) =>
              (value * 100).toFixed(0) + '%',
          },
        },
        xAxis: {
          type: 'category',
          data: dates,
          axisLine: {
            lineStyle: {
              color: utils.rgbaColor('#000', 0.01),
              type: 'dashed',
            },
          },
          axisLabel: {
            color: utils.getColor('gray-400'),
            margin: 15,
            formatter: function (value) {
              return new Date(value).toLocaleDateString('en-US', {month: 'short', day: 'numeric'});
            },
          },
        },
        series,
      });

      // Set the options to the chart
      chart.setOption(getDefaultOptions());

      // Apply additional configurations if needed
      // e.g., echartSetOption(chart, {}, getDefaultOptions);

      // Handle window resize to make the chart responsive
      window.addEventListener('resize', () => {
        chart.resize();
      });
    }
  }


  initPieChart() {
    let el = this.pieChartTarget;
    el.innerHTML = '';
    const innerDiv = document.createElement('div');
    innerDiv.setAttribute('style', 'min-height: 300px;');
    el.appendChild(innerDiv);

    let chart = echarts.init(innerDiv);

    if (chart) {
      let series = JSON.parse(this.pieChartTarget.dataset.series);

      // change .color with .itemStyle
      series.forEach(function (item) {
        item.itemStyle = {
          color: item.color
        };
      });

      let options = {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: 'Categories',
            type: 'pie',
            radius: '80%',
            label: {
              color: '#9da9bb'
            },
            data: series,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };

      chart.setOption(options);

      // Add click event listener
      chart.on('click', function (params) {
        // console.log(params);

        const ev = new CustomEvent("sentiment-category-click", { detail: { id: params.data.id } });
        document.body.dispatchEvent(ev);
      });

      // Resize chart on window resize
      window.addEventListener('resize', function () {
        chart.resize();
      });
    }
  }
}