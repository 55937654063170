import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="issue-form"
export default class extends Controller {

  // description - one text field, can change dynamically
  // stepsToReproduce - multiple text fields, the number can change dynamically
  static targets = ["description", "stepsToReproduce", "suggestions", "similarIssues"]
  static values = { issueId: Number, findSimilarEndpoint: String, developerView: Boolean }

  weights = {
      description: 0.4,
      stepsToReproduce: 0.3,
      screenshot: 0.08,
      videoClip: 0.1,
      logFile: 0.12
    };

  connect() {
    if (!this.developerViewValue) {
      this.calculateIssueStrength = this.calculateIssueStrength.bind(this);

      // Listen to changes in description
      this.descriptionTarget.addEventListener("input", this.calculateIssueStrength);
      this.observeStepsToReproduce();

      this.calculateIssueStrength();

      // listen for issue-strength-gauge-ready event
      document.body.addEventListener("issue-strength-gauge-ready", this.calculateIssueStrength);

      // find similar issues on description blur
      this.descriptionTarget.addEventListener("blur", () => this.findSimilarIssues());
    }

    // local storage issue saving

    this.saveToLocalStorage = this.saveToLocalStorage.bind(this);
    this.restoreFromLocalStorage = this.restoreFromLocalStorage.bind(this);
    this.useLocalStorage = true

    // Save values to local storage when they change
    this.descriptionTarget.addEventListener("input", this.saveToLocalStorage);
    this.stepsToReproduceTargets.forEach((step) =>
        step.addEventListener("input", this.saveToLocalStorage)
    );

    // Restore values from local storage when received document.body steps-ready event
    document.body.addEventListener("steps-ready", this.restoreFromLocalStorage);
  }

  async findSimilarIssues() {
    const description = this.descriptionTarget.value;

    // don't search for similar issues if description is empty
    if (description === "") {
      this.similarIssuesTarget.innerHTML = "";
      return;
    }

    // don't search for similar issues if description is too short
    if (description.length < 20) {
      this.updateMessage("Description is too short.", "text-600");
      return;
    }

    this.updateMessage("Searching for similar issues...", "text-600");

    try {
      const response = await fetch(this.findSimilarEndpointValue, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        body: JSON.stringify({ description: description })
      });

      if (response.ok) {
        const data = await response.json();
        this.showSimilarIssues(data.issues);
      } else {
        this.updateMessage("Error: Failed to fetch similar issues.", "text-600");
      }
    } catch (error) {
      this.updateMessage("Error: Failed to fetch similar issues.", "text-600");
      console.error(error);
    }
  }

  showSimilarIssues(issues) {
    const topIssues = issues.slice(0, 3);

    let html = "";

    if (topIssues.length > 0) {
      html += "<h6>Similar issues</h6>"
      html += topIssues.map(issue => `
        <div class="similar-issue">
          <a href="${issue.url}">${issue.title}</a>
          <span>(${issue.score * 100}%)</span>
        </div>
      `).join('');
    } else {
      html += '<div class="text-600">No similar issues found.</div>';
    }

    this.similarIssuesTarget.innerHTML = html;
  }

  updateMessage(message, className) {
    this.similarIssuesTarget.innerHTML = `<div class="${className}">${message}</div>`;
  }


  observeStepsToReproduce() {
    const stepsContainer = this.element.querySelector("#steps-container");

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          this.stepsToReproduceTargets.forEach((step) => {
            if (!step._inputEventListenerAttached) {
              step.addEventListener("input", this.calculateIssueStrength);

              if (this.useLocalStorage) {
                step.addEventListener("input", this.saveToLocalStorage);
              }
              step._inputEventListenerAttached = true;
            }
          });
        }
      }
    });

    observer.observe(stepsContainer, {
      childList: true,
      subtree: true
    });
  }

  calculateIssueStrength() {
    const description = this.descriptionTarget.value;
    const stepsToReproduce = this.stepsToReproduceTargets
        .map((step) => step.value)
        .filter((step) => step !== "")
        .join("\n");

    const descriptionScore = this.normalizeTextLength(description.length, 20, 150);
    const stepsScore = this.normalizeStepCount(stepsToReproduce.split("\n").length, 1, 4);
    const screenshotScore = this.hasScreenshot() ? 1 : 0;
    const videoClipScore = this.hasVideoClip() ? 1 : 0;
    const logFileScore = this.hasLogFile() ? 1 : 0;

    const submissionStrength =
        this.weights.description * descriptionScore +
        this.weights.stepsToReproduce * stepsScore +
        this.weights.screenshot * screenshotScore +
        this.weights.videoClip * videoClipScore +
        this.weights.logFile * logFileScore;

    let val = {
      submissionStrength,
      fieldScores: {
        description: descriptionScore,
        stepsToReproduce: stepsScore,
        screenshot: screenshotScore,
        videoClip: videoClipScore,
        logFile: logFileScore,
      },
      fieldMaxScores: {
        description: 1,
        stepsToReproduce: 1,
        screenshot: 1,
        videoClip: 1,
        logFile: 1,
      },
    };
    this.updateIssueStrength(val);
  }

  getTopSuggestions(fieldScores, fieldMaxScores, weights) {
    const suggestions = [
      {
        text: "Write a more detailed description",
        points: (fieldMaxScores.description - fieldScores.description) * weights.description * 100,
        field: "description",
      },
      {
        text: "Provide more steps to reproduce",
        points: (fieldMaxScores.stepsToReproduce - fieldScores.stepsToReproduce) * weights.stepsToReproduce * 100,
        field: "stepsToReproduce",
      },
      {
        text: "Provide a screenshot",
        points: (fieldMaxScores.screenshot - fieldScores.screenshot) * weights.screenshot * 100,
        field: "screenshot",
      },
      {
        text: "Provide a video clip",
        points: (fieldMaxScores.videoClip - fieldScores.videoClip) * weights.videoClip * 100,
        field: "videoClip",
      },
      {
        text: "Provide a log file",
        points: (fieldMaxScores.logFile - fieldScores.logFile) * weights.logFile * 100,
        field: "logFile",
      },
    ];

    // Sort suggestions by points in descending order
    suggestions.sort((a, b) => b.points - a.points);

    // Return top two suggestions
    return suggestions.slice(0, 2);
  }


  normalizeTextLength(length, minLength, maxLength) {
    return Math.max(0, Math.min(1, (length - minLength) / (maxLength - minLength)));
  }

  normalizeStepCount(stepCount, minSteps, maxSteps) {
    return Math.max(0, Math.min(1, (stepCount - minSteps) / (maxSteps - minSteps)));
  }

  updateIssueStrength(value) {
    const { submissionStrength, fieldScores, fieldMaxScores } = value;

    // ...
    // Update the strength gauge
    const ev = new CustomEvent("issue-strength-change", { detail: { value: submissionStrength * 100 } });
    document.body.dispatchEvent(ev);

    const topSuggestions = this.getTopSuggestions(fieldScores, fieldMaxScores, this.weights);

    // Clear the existing suggestions
    this.suggestionsTarget.innerHTML = "";

    // Create and insert the new suggestions list
    const list = document.createElement("ul");
    topSuggestions.forEach((suggestion) => {
      const listItem = document.createElement("li");
      listItem.textContent = `${suggestion.text} (+${suggestion.points.toFixed(0)})`;
      list.appendChild(listItem);
    });
    this.suggestionsTarget.appendChild(list);

    // Display the top suggestions
    // Replace the following line with your code to display the suggestions on your webpage
    // console.log("Top suggestions:", topSuggestions.map((s) => `${s.text} (+${s.points.toFixed(0)})`));
  }


  // this won't change dynamically, so we don't need to use targets
  hasScreenshot() {
    // find children (on any depth) by '.sel-has-screenshot' selector, if any, return true
    // if no children, return false

    if (this.element.querySelector(".sel-has-screenshot")) {
      return true;
    } else {
      return false;
    }
  }

  // this won't change dynamically, so we don't need to use targets
  hasVideoClip() {
    // find children (on any depth) by '.sel-has-video-clip' selector, if any, return true
    // if no children, return false

    if (this.element.querySelector(".sel-has-video-clip")) {
      return true;
    } else {
      return false;
    }
  }

  // this won't change dynamically, so we don't need to use targets
  hasLogFile() {
    // find children (on any depth) by '.sel-has-log-file' selector, if any, return true
    // if no children, return false

    if (this.element.querySelector(".sel-has-log-file")) {
      return true;
    } else {
      return false;
    }
  }

  saveToLocalStorage() {
    const issueId = this.issueIdValue;
    localStorage.setItem(`issueFormDescription_${issueId}`, JSON.stringify({
      originalValue: this.descriptionTarget.dataset.originalValue,
      userValue: this.descriptionTarget.value
    }));

    const stepsData = this.stepsToReproduceTargets.map((step) => ({
      originalValue: step.dataset.originalValue,
      userValue: step.value
    }));
    localStorage.setItem(`issueFormSteps_${issueId}`, JSON.stringify(stepsData));
  }

  restoreFromLocalStorage() {
    const issueId = this.issueIdValue;
    const descriptionData = JSON.parse(localStorage.getItem(`issueFormDescription_${issueId}`));
    if (
        descriptionData &&
        descriptionData.originalValue === this.descriptionTarget.dataset.originalValue
    ) {
      this.descriptionTarget.value = descriptionData.userValue;
    }

    const stepsData = JSON.parse(localStorage.getItem(`issueFormSteps_${issueId}`));
    if (stepsData && Array.isArray(stepsData)) {
      stepsData.forEach((stepData, index) => {
        if (
            index < this.stepsToReproduceTargets.length &&
            stepData.originalValue === this.stepsToReproduceTargets[index].dataset.originalValue
        ) {
          this.stepsToReproduceTargets[index].value = stepData.userValue;
        } else if (stepData.userValue) {
          const ev = new CustomEvent("steps-add-step");
          document.body.dispatchEvent(ev);

          // Wait for the new step to be added before setting its value
          requestAnimationFrame(() => {
            this.stepsToReproduceTargets[index].value = stepData.userValue;
          });
        }
      });

      // Check if the last step is not empty and add a new step
      const lastStep = this.stepsToReproduceTargets[this.stepsToReproduceTargets.length - 1];
      if (lastStep.value.trim() !== "") {
        const ev = new CustomEvent("steps-add-step");
        document.body.dispatchEvent(ev);
      }
    }

    if (!this.developerViewValue) { // not a developer view
      // need to wait for the new step to be added before recalculating
      requestAnimationFrame(() => {
        this.calculateIssueStrength();
      });
    }
  }

}
