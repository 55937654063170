import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="link"
export default class extends Controller {

  static values = { url: String }
  connect() {
  }

  fetchTurboFrame(event) {
    const response = fetch(this.urlValue, {
      headers: { "Accept": "text/vnd.turbo-stream.html" },
      credentials: "same-origin"
    }).then (response => response.text())
    .then(html => Turbo.renderStreamMessage(html));

    event.preventDefault();
  }
}
