// file_size_validator_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]
  static values = {
    maxSize: Number
  }

  connect() {

    console.log(this)
    if (!this.hasMaxSizeValue) {
      console.warn("Max file size is not set")
    }
  }

  validate() {
    const file = this.element.files[0]
    if (!file) return

    const fileSize = file.size
    const maxSize = this.maxSizeValue

    if (fileSize > maxSize) {
      this.element.value = ''
      const maxSizeMB = Math.round(maxSize / (1024 * 1024) * 100) / 100
      alert(`File size exceeds the limit of ${maxSizeMB} MB`)
    }
  }
}