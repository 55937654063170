import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["frame"]
  static values = { endpoint: String, csrfToken: String }

  connect() {
    // Optional: Initialize anything if needed
  }

  clarify(event) {
    event.preventDefault()

    // Show the progress indicator in the Turbo Frame
    this.frameTarget.innerHTML = `
      <div class="d-flex align-items-center">
        <strong>Loading...</strong>
        <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
      </div>
    `

    // Start a 20-second timeout
    const timeoutId = setTimeout(() => {
      this.frameTarget.innerHTML = `
        <div class="mt-2">
          <p>Request timed out. Please try again.</p>
          <button type="button" data-action="clarify#clarify" class="btn btn-warning">
            Try Again
          </button>
        </div>
      `
    }, 20000) // 20,000 milliseconds = 20 seconds

    console.log(this.endpoint());

    // Send the POST request to the clarify endpoint
    fetch(this.endpoint(), {
      method: "POST",
      headers: {
        "Accept": "text/vnd.turbo-stream.html",
        "X-CSRF-Token": this.csrfTokenValue
      },
      credentials: "same-origin"
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Network response was not ok (${response.status})`)
        }
        return response.text()
      })
      .then(html => {
        clearTimeout(timeoutId)
        Turbo.renderStreamMessage(html);
      })
      .catch(error => {
        clearTimeout(timeoutId)
        console.error("Clarify request failed:", error)
        this.frameTarget.innerHTML = `
          <div class="mt-2">
            <p>Something went wrong. Please try again.</p>
            <button type="button" data-action="clarify#clarify" class="btn btn-warning">
              Try Again
            </button>
          </div>
        `
      })
  }

  endpoint() {
    return this.endpointValue
  }
}
