import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flatpickr"
export default class extends Controller {
  static values = { options: Object }
  connect() {
    this.fpInstance = flatpickr(this.element, this.optionsValue)
  }

  disconnect() {
    this.fpInstance.destroy()
  }
}
